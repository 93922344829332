.tlbx-color-swatch-wrapper {
  display: flex;
  flex-wrap: wrap; }

.tlbx-color-swatch {
  flex: 0 0 14%;
  padding-right: 1.6%;
  margin: 1em 0;
  margin-bottom: 2em; }

.tlbx-color-swatch-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
  height: 12vh; }
  .tlbx-color-swatch-color:hover .tlbx-color-swatch-btn {
    opacity: 1; }

.tlbx-color-swatch-btn {
  opacity: 0;
  display: inline-block;
  margin: 0 auto;
  padding: 1em;
  background: #003baf;
  color: #f0f0f0;
  border: none;
  transition: opacity 0.3s; }
  .tlbx-color-swatch-btn:hover {
    cursor: pointer; }

.tlbx-color-swatch-infos h5 {
  margin: 0.5em 0; }

.tlbx-color-swatch-infos h6 {
  text-transform: uppercase;
  margin: 0.5em 0; }
