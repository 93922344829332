.tlbx-sidebar-item {
  display: flex;
  margin: 0 0 0.5rem 0;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  border: 0;
  background: none;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 1rem; }
  .tlbx-sidebar-item:hover {
    color: #012f4d; }
  .tlbx-sidebar-item:focus, .tlbx-sidebar-item:active {
    outline: none;
    box-shadow: none; }
  .tlbx-sidebar-item:after {
    content: '';
    line-height: 0;
    align-self: center;
    margin-left: auto;
    margin-right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #3474a8;
    font-size: 0;
    line-height: 0; }
  .tlbx-open .tlbx-sidebar-item:after {
    transform: rotate(180deg); }

.tlbx-sidebar-collapse {
  transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1); }

.tlbx-sidebar-item-list {
  margin-top: 0;
  list-style: none;
  padding: 0; }
  .tlbx-sidebar-item-list strong,
  .tlbx-sidebar-item-list em {
    padding-left: 2rem; }
  .tlbx-sidebar-item-list a {
    display: block;
    position: relative;
    padding: 0.25rem 0.5rem 0.25rem;
    padding-left: 2rem;
    color: #010a15;
    transition: color 250ms ease; }
    .tlbx-sidebar-item-list a:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 0 solid #3474a8;
      transition: border-width 100ms ease; }
    .tlbx-sidebar-item-list a.active, .tlbx-sidebar-item-list a:hover {
      color: #3474a8;
      text-decoration: none; }
      .tlbx-sidebar-item-list a.active:before, .tlbx-sidebar-item-list a:hover:before {
        border-left-width: 4px; }
    .tlbx-sidebar-item-list a.active {
      font-weight: bold; }
  .tlbx-sidebar-item-list ul a,
  .tlbx-sidebar-item-list ul strong,
  .tlbx-sidebar-item-list ul em {
    padding-left: 3rem; }
  .tlbx-sidebar-item-list ul ul a,
  .tlbx-sidebar-item-list ul ul strong,
  .tlbx-sidebar-item-list ul ul em {
    padding-left: 4rem; }
  .tlbx-sidebar-item-list ul ul ul a,
  .tlbx-sidebar-item-list ul ul ul strong,
  .tlbx-sidebar-item-list ul ul ul em {
    padding-left: 5rem; }

.tlbx-sidebar-item-list-empty {
  display: block;
  margin-left: 1rem; }
