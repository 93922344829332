.tlbx-item-preview {
  position: relative;
  padding: 1.5rem;
  border: 2px solid #f2f2f4; }

.tlbx-item-code {
  margin-top: 0; }
  .tlbx-item-code pre {
    margin-top: 0;
    max-height: 80vh; }
    .tlbx-item-code pre code {
      font-family: Monaco, monospace; }
    .tlbx-item-code pre .react-syntax-highlighter-line-number {
      line-height: 1.47em; }

.tlbx-actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -0.8rem; }

.tlbx-actions-link {
  position: relative;
  z-index: 1;
  padding: 0 0.5em;
  border: 1px solid #3474a8;
  background-color: #fff;
  border-radius: 3px;
  color: #3474a8;
  font-size: 12px;
  text-decoration: none; }
  .tlbx-actions-link:hover {
    color: #012f4d;
    border-color: #012f4d;
    text-decoration: none; }

.tlbx-hidden {
  display: none; }
