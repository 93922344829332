body {
  margin: 0;
  padding: 0; }

.tlbx-h2 {
  text-align: center; }

.styleguide {
  display: flex;
  display: -ms-grid;
  display: grid;
  -ms-grid-template-columns: 60px 240px 1fr;
  grid-template-columns: 60px 240px 1fr;
  grid-template-columns: 60px 240px 1fr;
  min-height: 100vh;
  max-width: 100%;
  font-family: inherit, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: inherit; }
  @media only screen and (max-width: 767px) {
    .styleguide {
      display: block;
      overflow: hidden; } }

.tlbx-toolbar-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 5px;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  box-sizing: border-box;
  flex: 0 0 60px;
  grid-column: 1;
  background-color: #003baf;
  color: white; }
  @media only screen and (max-width: 767px) {
    .tlbx-toolbar-wrapper {
      width: 100%;
      bottom: auto; } }

.tlbx-sidebar-wrapper {
  flex: 0 0 240px;
  -ms-grid-column: 2;
  grid-column: 2;
  background-color: #f2f2f4; }
  @media only screen and (max-width: 767px) {
    .tlbx-sidebar-wrapper {
      position: fixed;
      z-index: 998;
      top: 60px;
      bottom: 0;
      right: 0;
      overflow-y: auto;
      min-height: 100vh;
      max-width: 240px;
      box-shadow: -3px 0 20px 0 rgba(0, 0, 0, 0.3);
      transform: translateX(100%);
      transition: transform 0.3s; }
      .tlbx-sidebar-wrapper.tlbx-sidebar-open {
        transform: translateX(0); } }

.tlbx-content-wrapper {
  flex: 1 1 auto;
  -ms-grid-column: 3;
  grid-column: 3;
  align-self: start;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .tlbx-content-wrapper {
      padding-top: 60px;
      box-sizing: border-box;
      width: 100%; } }

.tlbx-content {
  padding: 1.2rem 2rem; }

.tlbx-notes table,
.tlbx-doc-markdown-wrapper table {
  max-width: 100%;
  margin-bottom: 1rem; }

.tlbx-notes table th,
.tlbx-notes table td,
.tlbx-doc-markdown-wrapper table th,
.tlbx-doc-markdown-wrapper table td {
  padding: .75rem;
  border-top: 1px solid #dee2e6; }

.tlbx-notes table thead th,
.tlbx-doc-markdown-wrapper table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }
