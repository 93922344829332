.tlbx-toolbar {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .tlbx-toolbar {
      flex-direction: row;
      justify-content: flex-end; } }

.tlbx-toolbar-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  color: #fff;
  font-weight: 900;
  font-size: 1.4em;
  opacity: 1; }
  .tlbx-toolbar-btn:hover {
    cursor: pointer; }
  .tlbx-toolbar-btn:focus, .tlbx-toolbar-btn:active {
    outline: none;
    box-shadow: none; }
  .tlbx-toolbar-btn.tlbx-active {
    opacity: 0.5; }
  @media only screen and (max-width: 767px) {
    .tlbx-toolbar-btn {
      padding: 0;
      font-size: 20px; }
      .tlbx-toolbar-btn.tlbx-brand {
        margin-right: auto; } }

.tlbx-toolbar-toggle-menu {
  display: none; }
  @media only screen and (max-width: 767px) {
    .tlbx-toolbar-toggle-menu {
      display: flex; } }
