.tlbx-alert {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
  padding: 8px 20px;
  background: #FFD62B;
  font-size: 16px; }
  .tlbx-alert.open {
    display: flex; }
  .tlbx-alert * {
    color: #010a15; }
  .tlbx-alert > div {
    flex: 1 1 100%;
    margin: 0; }
  .tlbx-alert p,
  .tlbx-alert ul {
    margin: 0; }
  .tlbx-alert ul {
    padding-left: 1em; }
  .tlbx-alert span {
    position: relative;
    top: 0.1em;
    font-size: 2.2em;
    padding-right: 15px; }
  .tlbx-alert button {
    background: transparent;
    border: 0;
    font-size: 2em;
    margin-left: 15px;
    margin-top: -0.2em;
    transition: color 0.25s; }
    .tlbx-alert button:hover {
      cursor: pointer;
      color: #FA351F; }
