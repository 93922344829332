.tlbx-notes {
  max-width: 700px;
  line-height: 1.55em; }

.tlbx-single-full {
  min-height: 100vh; }
  .tlbx-single-full .tlbx-item-preview {
    border: 0; }
  .tlbx-single-full .tlbx-single-full-intro h1 {
    font-size: 1rem;
    margin: 0; }

.tlbx-single-full-intro {
  padding: 1rem;
  background-color: #f2f2f4; }

.tlbx-variant-heading {
  margin-top: 1.2em; }
