.tblx-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  top: 0.1em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  color: inherit;
  fill: currentColor;
  transition: transform 0.2s; }
