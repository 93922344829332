.tblx-loader {
  padding: 30px;
  color: #003baf;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  .tlbx-item-preview .tblx-loader {
    padding: 5px; }
